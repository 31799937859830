/*----------------------------------------*/
/*  04. FEATURES CSS START
/*----------------------------------------*/

.features {
    &__item {
        position: relative;
        padding: 30px;
        background: $white;
        @include transition(.3s);

        @media #{$xs} {
            border-top: 1px solid $heading-color;
        }

        &.bg {
            background: $grey;

            @media #{$xs} {
                background: $white;
            }
        }

        &:hover {
            @include box-shadow(0px 0px 50px 0px rgba(0, 0, 0, 0.08));
            z-index: 99;

            &.bg {
                background: $white;
            }

            & .features__content {
                & h3 {
                    /* color: $theme-color; */
                    position: relative;
                }
            }

            & .features__icon {
                & i {
                    color: $white;
                    background: $heading-color;
                    -webkit-transform: translate3d(0, -10px, 0);
                    -moz-transform: translate3d(0, -10px, 0);
                    -ms-transform: translate3d(0, -10px, 0);
                    -o-transform: translate3d(0, -10px, 0);
                    transform: translate3d(0, -10px, 0);
                }
            }
        }
    }

    &__content {
        position: relative;

        & h3 {
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 0px;
        }
    }

    &__icon {
        margin-bottom: 20px;

        & i {
            font-size: 30px;
            color: $heading-color;
            background: $grey-8;
            height: 60px;
            width: 60px;
            line-height: 55px;
            text-align: center;
            display: inline-block;
            @include border-radius(50px);
            backface-visibility: hidden;
            -webkit-transform: translate3d(0, 0, 0);
            -moz-transform: translate3d(0, 0, 0);
            -ms-transform: translate3d(0, 0, 0);
            -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
            -webkit-transition: transform .3s cubic-bezier(.21, .6, .44, 2.18);
            -moz-transition: transform .3s cubic-bezier(.21, .6, .44, 2.18);
            -ms-transition: transform .3s cubic-bezier(.21, .6, .44, 2.18);
            -o-transition: transform .3s cubic-bezier(.21, .6, .44, 2.18);
            transition: transform .3s cubic-bezier(.21, .6, .44, 2.18);
        }
    }
}