/*----------------------------------------*/
/*  21. PORTFOLIO CSS START
/*----------------------------------------*/
.portfolio {
    &__item {
        &:hover {
            & .portfolio__thumb {
                &::before {
                    opacity: .9;
                }
            }

            & .portfolio__plus {
                visibility: visible;
                opacity: 1;
                top: 50%;
            }

            & .portfolio__more {
                visibility: visible;
                opacity: 1;
                left: 0;
            }
        }
    }

    &__thumb {
        position: relative;

        & img {
            @include border-radius(10px);
        }

        &::before {
            position: absolute;
            content: '';
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-image: -moz-linear-gradient(60deg, rgb(255, 36, 247) 0%, rgb(120, 28, 226) 100%);
            background-image: -webkit-linear-gradient(60deg, rgb(255, 36, 247) 0%, rgb(120, 28, 226) 100%);
            background-image: -ms-linear-gradient(60deg, rgb(255, 36, 247) 0%, rgb(120, 28, 226) 100%);
            background-image: linear-gradient(60deg, rgb(255, 36, 247) 0%, rgb(120, 28, 226) 100%);
            @include border-radius(10px);
            opacity: 0;
        }
    }

    &__content {
        padding: 35px 0;

        & h4 {
            font-size: 24px;
            margin-bottom: 5px;

            & a {
                &:hover {
                    color: $theme-color;
                }
            }
        }

        & p {
            margin-bottom: 0;
        }
    }

    &__plus {
        top: 60%;
        left: 50%;
        @include transform(translate(-50%, -50%));
        visibility: hidden;
        opacity: 0;
        z-index: 2;

        & button {
            display: inline-block;
            width: 42px;
            height: 42px;
            line-height: 38px;
            text-align: center;
            border: 2px solid rgba($color: $white, $alpha: .4);
            color: $white;
            font-size: 20px;
            // border: none;
            background: none;
            @include border-radius(50%);
            position: relative;
            overflow: hidden;

            &:hover {
                color: $black;
                border-color: $white;
                background: $white
            }

            &:hover {
                & i:first-child {
                    left: -120%;
                }

                & i:last-child {
                    left: 50%;
                }
            }

            & i {
                position: absolute;
                top: 50%;
                left: 50%;
                margin-top: -2px;
                @include transform(translate(-50%, -50%));
                @include transition(.2s);

                &:last-child {
                    left: 120%;
                }
            }
        }
    }

    &__more {
        bottom: 30px;
        background: $white;
        z-index: 1;
        padding: 3px 0;
        width: 100%;
        visibility: hidden;
        opacity: 0;
        left: -30px;

        & .link-btn {
            font-size: 16px;

            &:hover {
                color: $black;
            }
        }
    }

    &__load {
        & a {
            & i {
                margin-left: 0;
                padding-right: 5px;
            }
        }
    }

    &__menu {
        & span {
            color: $black;
        }

        & button {
            color: $black-soft-4;
            padding: 0 20px;
            background: transparent;

            @media #{$sm} {
                padding: 0 10px;
            }

            @media #{$xs} {
                display: block;
                padding: 10px 0px;
            }

            &.active {
                color: $theme-color;
            }

            &:hover {
                color: $theme-color;
            }
        }
    }

    &__details {
        &-content {
            & h1 {
                font-size: 50px;
                margin-bottom: 30px;

                @media #{$xs} {
                    font-size: 40px;
                }
            }
        }
    }

    &__meta {
        & h4 {
            display: inline-block;
            font-family: $hanken;
            font-weight: 700;
            font-size: 16px;
        }
    }

    &__info {
        & h3 {
            font-family: $hanken;
            font-weight: 700;
            font-size: 16px;
            margin-bottom: 7px;
        }
    }

    &__overview {
        & h2 {
            font-size: 20px;
        }

        & p {
            font-size: 18px;
            line-height: 30px;
        }
    }

    &__pagination {
        &-wrapper {
            padding-top: 30px;
            border-top: 1px solid $border-9;
        }
    }
}

.portfolioBox {
    height: 470px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;

    @media #{$xs} {
        height: 240px;
    }
}

.portfolioBox .portfolio__item {
    width: calc(50% - 20px);
    flex: 1;
}

.portfolioBox .portfolio__item img {
    height: 215px;
    object-fit: cover;

    @media #{$xs} {
        height: 100px;
    }
}

.portfolioBox .portfolio__item:first-child img {
    height: 450px;

    @media #{$xs} {
        height: 220px;
    }
}