/*----------------------------------------*/
/*  14. EXPART CSS START
/*----------------------------------------*/

.expart {
    &__tab {
        @include box-shadow(0px 30px 40px 0px rgba(2, 0, 40, 0.1));
        @include border-radius(20px);
        overflow: hidden;
    }

    &__nav {
        & li {
            @media #{$xs} {
                display: block;
                width: 100%;
            }
        }

        & .nav-link {
            height: 44px;
            line-height: 44px;
            padding: 0 30px;
            font-size: 14px;
            color: $black;
            background: $grey-13;
            font-weight: 700;
            @include border-radius(6px 6px 0px 0px);
            margin-left: 10px;

            &.active {
                background: $theme-color;
                color: $white;
            }

            @media #{$xs} {
                margin-left: 0px;
            }
        }
    }

    &__tab-content {
        position: relative;

        & h3 {
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 15px;
        }

        & p {
            margin-bottom: 40px;
        }
    }

    &__content {
        padding: 50px;

        @media #{$xs} {
            padding: 30px;
        }
    }

    &__thumb {
        position: absolute;
        width: 50%;
        height: 100%;
        top: 0;
        left: 0;
        @include background();

        @media #{$md} {
            display: none;
        }

        @media #{$sm} {
            display: none;
        }

        @media #{$xs} {
            display: none;
        }
    }
}