/*----------------------------------------*/
/*  05. SERVICES CSS START
/*----------------------------------------*/

.services {
    &__area {
        position: relative;
    }

    &__item {
        padding: 30px 22px;
        background: $white;
        border: 1px solid $grey-4;
        @include border-radius(10px);

        &:hover {
            & .services__icon {
                & img {
                    -webkit-transform: translate3d(0, -10px, 0);
                    -moz-transform: translate3d(0, -10px, 0);
                    -ms-transform: translate3d(0, -10px, 0);
                    -o-transform: translate3d(0, -10px, 0);
                    transform: translate3d(0, -10px, 0);
                }
            }
        }
    }

    &__icon {
        text-align: center;

        & img {
            @include border-radius(10px);
            backface-visibility: hidden;
            -webkit-transform: translate3d(0, 0, 0);
            -moz-transform: translate3d(0, 0, 0);
            -ms-transform: translate3d(0, 0, 0);
            -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
            -webkit-transition: transform .3s cubic-bezier(.21, .6, .44, 2.18);
            -moz-transition: transform .3s cubic-bezier(.21, .6, .44, 2.18);
            -ms-transition: transform .3s cubic-bezier(.21, .6, .44, 2.18);
            -o-transition: transform .3s cubic-bezier(.21, .6, .44, 2.18);
            transition: transform .3s cubic-bezier(.21, .6, .44, 2.18);
        }
    }

    &__content {
        text-align: center;

        & h3 {
            font-size: 22px;
            font-weight: 500;
            margin: 0px;
        }
    }
}