/*----------------------------------------*/
/*  02. HEADER CSS START
/*----------------------------------------*/

.logo-gradient {
    display: none;
}

.header {
    &__search-wrapper {
        position: absolute;
        top: 100%;
        left: 0;
        right: auto;
        width: 0%;
        height: 100px;
        background: $black;
        color: $white;
        border: none;
        outline: none;
        padding: 15px;
        @include transition(.3s);
        visibility: hidden;
        opacity: 0;
        z-index: 11;

        &.opened {
            width: 100%;
            left: auto;
            right: 0;
            visibility: visible;
            opacity: 1;
        }

        & input {
            width: 100%;
            padding: 0px;
            padding-right: 30px;
            border: none;
            height: 70px;
            color: $white;
            font-size: 24px;
            font-weight: 400;
            background-color: transparent;

            &::placeholder {
                color: $grey-11;
                text-transform: capitalize;
                font-size: 20px;
            }

            &:focus {
                border: none;
                outline: none;

                &::placeholder {
                    opacity: 0;
                }
            }
        }

        & button {
            position: absolute;
            top: 50%;
            right: 0;
            @include transform(translateY(-50%));
            background: transparent;
            font-size: 24px;
        }
    }

    &__transparent {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 11;
    }

    &__top {
        border-bottom: 1px solid $border-2;

        &-4 {
            border-bottom: 1px solid #f0f0f1;
        }
    }

    &__social {
        & ul {
            & li {
                display: inline-block;
                border-right: 1px solid $border-2;
                margin-left: -3px;

                &:last-child {
                    border-right: 0;
                }

                & a {
                    display: inline-block;
                    width: 50px;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    color: $white;
                    font-size: 14px;

                    &:hover {
                        background: rgba($color: $white, $alpha: .4);
                    }
                }
            }
        }
    }

    &__info {
        & ul {
            & li {
                display: inline-block;
                margin-left: 30px;
                padding-left: 20px;
                border-left: 1px solid $border-2;

                @media #{$md} {
                    margin-left: 15px;
                }

                &:first-child {
                    border-left: 0;
                }

                & a {
                    display: inline-block;
                    font-size: 14px;
                    color: $white;
                    height: 40px;
                    line-height: 40px;

                    &.search-toggle {
                        width: 40px;
                    }

                    & i {
                        margin-right: 5px;
                    }
                }
            }
        }
    }

    &__bottom {
        @media #{$md} {
            padding: 20px 0;
        }

        @media #{$sm} {
            padding: 20px 0;
        }

        @media #{$xs} {
            padding: 20px 0;
        }

        &.sticky {
            left: 0;
            margin: auto;
            position: fixed !important;
            top: 0;
            width: 100%;
            box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.07);
            z-index: 999;
            -webkit-animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
            animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
            -webkit-box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
            background: $white;

            @keyframes fadeInDown {
                0% {
                    opacity: 0;
                    -webkit-transform: translateY(-20px);
                    -ms-transform: translateY(-20px);
                    transform: translateY(-20px);
                }

                100% {
                    opacity: 1;
                    -webkit-transform: translateY(0);
                    -ms-transform: translateY(0);
                    transform: translateY(0);
                }
            }

            .fadeInDown {
                -webkit-animation-name: fadeInDown;
                animation-name: fadeInDown;
            }

            & .logo-gradient {
                display: block;
            }

            & .logo {
                display: none;
            }

            & .main-menu {
                & ul {
                    & li {
                        & a {
                            color: $black;
                        }

                        &:hover {
                            &>a {
                                color: $theme-color;
                            }
                        }
                    }
                }
            }

            & .header__btn {
                & a {
                    background: $theme-color;
                    border-color: $theme-color;
                    color: $white;

                    &:hover {
                        background: $white;
                        color: $theme-color;
                    }
                }
            }

            & .sidebar-toggle-btn {
                & .line {
                    background: $theme-color;
                }
            }
        }

        &-2 {
            padding: 4px 0;

            @media #{$md} {
                padding: 15px 0;
            }

            @media #{$sm} {
                padding: 15px 0;
            }

            @media #{$xs} {
                padding: 15px 0;
            }
        }
    }

    &__shape {
        top: 0;
        left: 65px;
        z-index: 1;

        @media #{$laptop} {
            left: 12%;
        }
    }
}

/* header style */
.header__style-4 {
    & .main-menu {
        & ul {
            & li {
                & a {
                    color: #646175;
                }
            }
        }
    }

    & .header__btn {
        & a {
            color: #646175;

            &:hover {
                color: $white;
            }
        }
    }
}

.header__style-5 {
    & .header__btn {
        & a {
            color: $white;

            &:hover {
                color: $black;
            }
        }
    }
}

.header__style-6 {
    & .header__top-4 {
        border-bottom: 1px solid #f0f0f1;
    }

    & .header__social {
        & ul {
            & li {
                border-right: 1px solid #f0f0f1;

                & a {
                    color: #686777;

                    &:hover {
                        background: $theme-color;
                        color: $white;
                    }
                }
            }
        }
    }

    & .header__info {
        & ul {
            & li {
                border-left: 1px solid #f0f0f1;

                & a {
                    color: #686777;

                    &:hover {
                        color: $theme-color;
                    }
                }
            }
        }
    }
}


/* MAIN MENU CSS START */

.main-menu {
    & ul {
        & li {
            display: inline-block;
            margin-right: 40px;
            position: relative;

            @media #{$lg} {
                margin-right: 25px;
            }

            &:last-child {
                margin-right: 0;
            }

            & a {
                font-size: 16px;
                font-weight: 700;
                text-transform: capitalize;
                color: $white;
                display: inline-block;
                padding: 20px 0;
                padding-bottom: 25px;
            }

            &:hover {
                &>a {
                    color: $white;
                }

                & ul.submenu {
                    top: 100%;
                    visibility: visible;
                    opacity: 1;
                }
            }

            /* submenu css start */

            & ul.submenu {
                position: absolute;
                top: 110%;
                left: 0;
                min-width: 200px;
                padding: 15px 0;
                background: $white;
                border-top: 4px solid $theme-color;
                z-index: 11;
                visibility: hidden;
                opacity: 0;
                @include transition(.3s);
                box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.07);

                & li {
                    display: block;
                    margin-right: 0;
                    padding: 6px 25px 6px 25px;

                    & a {
                        font-size: 15px;
                        display: inline-block;
                        padding: 0;
                        color: $black;
                        position: relative;

                        &::before {
                            content: "";
                            width: 0;
                            height: 1px;
                            bottom: 0;
                            position: absolute;
                            left: auto;
                            right: 0;
                            z-index: -1;
                            transition: width .6s cubic-bezier(.25, .8, .25, 1) 0s;
                            background: $theme-color;
                        }
                    }

                    &:hover {
                        &>a {
                            color: $theme-color;

                            &::before {
                                width: 100%;
                                left: 0;
                                right: auto;
                            }
                        }

                        &>ul.submenu {
                            top: 0;
                            visibility: visible;
                            opacity: 1;
                        }
                    }

                    & ul.submenu {
                        top: 10%;
                        left: 100%;
                        visibility: hidden;
                        opacity: 0;
                    }
                }
            }

        }
    }

    &.header__style-1-menu {
        & ul {
            & li {
                & a {
                    padding: 23px 0;
                    padding-bottom: 28px;
                }
            }
        }
    }

    &.header__style-4-menu {
        & ul {
            & li {
                & a {
                    color: #686777;

                    &:hover {
                        color: $theme-color;
                    }
                }
            }
        }
    }
}

.main-menu-3 {
    & ul {
        & li {
            & a {
                color: $black;
            }

            &:hover {
                &>a {
                    color: $theme-color;
                }
            }
        }
    }
}


/* sidebar css start */

.sidebar-toggle-btn {
    @media #{$md} {
        margin-left: 50px;
    }

    @media #{$sm} {
        margin-left: 50px;
    }

    @media #{$xs} {
        margin-left: 50px;
    }

    & .line {
        width: 30px;
        height: 3px;
        background-color: $white;
        display: block;
        margin: 6px auto;
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }

    &:hover {
        cursor: pointer;

        & .line {
            -webkit-transform: rotate(-30deg);
            -ms-transform: rotate(-30deg);
            -o-transform: rotate(-30deg);
            -moz-transform: rotate(-30deg);
            transform: rotate(-30deg);

            &:nth-child(1) {
                width: 10px;
            }

            &:nth-child(2) {
                width: 20px;
            }
        }
    }

    &-3 {
        & .line {
            background-color: $theme-color;
        }
    }
}

.sidebar {
    &__area {
        position: fixed;
        right: -340px;
        top: 0;
        width: 320px;
        height: 100%;
        background: #1b133f none repeat scroll 0 0;
        overflow-y: scroll;
        @include box-shadow(-5px 0 20px -5px rgba(0, 0, 0, 0.5));
        -webkit-transition: all .3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
        -moz-transition: all .3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
        transition: all .3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
        z-index: 9999;

        &.sidebar-opened {
            right: 0px;
        }
    }

    &__wrapper {
        position: relative;
        padding: 30px;
    }

    &__tab {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;

        & .nav-tabs {
            background: #130d2d;
            border-bottom: 0;

            & .nav-item {
                text-align: center;
                width: 50%;

                & .nav-link {
                    color: $white;
                    background-color: transparent;
                    border: none;
                    font-size: 16px;
                    font-weight: 700;
                    text-transform: uppercase;
                    @include border-radius(0);
                    border-bottom: 2px solid transparent;

                    &.active {
                        background: #1b133f;
                    }

                    &:hover {
                        background: $white;
                        color: $black;
                    }
                }
            }
        }
    }

    &__content {
        margin-top: 40px;
    }

    &__close {
        position: absolute;
        top: 70px;
        right: 80px;

        &-btn {
            transition: all 450ms cubic-bezier(.4, .25, .3, 1.3);
            width: 40px;
            height: 40px;
            position: absolute;
            text-align: center;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
            justify-content: center;
            align-items: center;
            color: $white;
            border: 1px solid #2a3a57;
            border-radius: 50%;
            cursor: pointer;
            overflow: hidden;
            background: transparent;
            z-index: 99;

            &:focus {
                border: 1px solid #2a3a57;
            }

            & span {
                transition: all 400ms cubic-bezier(.4, .25, .3, 1.3) 100ms;
                position: absolute;
                top: 50%;
                left: 50%;
                font-size: 14px;
                font-weight: 900;
                line-height: 40px;
                vertical-align: middle;

                &:first-of-type {
                    transform: translate(-50%, -50%);
                    -webkit-transform: translate(-50%, -50%);
                    -moz-transform: translate(-50%, -50%);
                    -ms-transform: translate(-50%, -50%);
                    -o-transform: translate(-50%, -50%);
                }

                &:last-of-type {
                    text-transform: uppercase;
                    transform: translate(-50%, 50%);
                    -webkit-transform: translate(-50%, 50%);
                    -moz-transform: translate(-50%, 50%);
                    -ms-transform: translate(-50%, 50%);
                    -o-transform: translate(-50%, 50%);
                }
            }

            &:hover {
                width: 100px;
                border-radius: 0;

                & span:first-of-type {
                    transform: translate(-50%, -150%);
                    -webkit-transform: translate(-50%, -150%);
                    -moz-transform: translate(-50%, -150%);
                    -ms-transform: translate(-50%, -150%);
                    -o-transform: translate(-50%, -150%);
                }

                & span:last-of-type {
                    transform: translate(-50%, -50%);
                    -webkit-transform: translate(-50%, -50%);
                    -moz-transform: translate(-50%, -50%);
                    -ms-transform: translate(-50%, -50%);
                    -o-transform: translate(-50%, -50%);
                }
            }
        }
    }

    &__info {
        & p {
            color: $grey-11;
            margin-bottom: 30px;
            line-height: 28px;
            font-size: 14px;
        }
    }

    &__contact {
        & ul {
            & li {
                margin-bottom: 8px;
                display: flex;

                & .icon {
                    & i {
                        line-height: 27px;
                        color: $grey-11;
                        margin-right: 15px;
                    }
                }

                & .text {
                    & span {
                        color: $grey-11;
                        font-weight: 700;

                        & a {
                            color: $grey-11;
                        }
                    }
                }

                &:hover {

                    & i,
                    & span {
                        color: $white;

                        & a {
                            color: $white;
                        }
                    }
                }
            }
        }
    }

    &__search {
        margin-top: 30px;
        position: relative;

        & input {
            width: 100%;
            height: 50px;
            line-height: 50px;
            @include border-radius(10px);
            border: none;
            outline: none;
            padding: 0 15px;
            background: #292151;
            color: $grey-11;

            &::placeholder {
                color: $grey-11;
            }
        }

        & button {
            position: absolute;
            top: 0;
            right: 0;
            height: 50px;
            width: 50px;
            line-height: 50px;
            color: $grey-11;
            background: transparent;
        }
    }
}

.body-overlay {
    background-color: rgba($color: #000000, $alpha: .5);
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 999;
    left: 0;
    opacity: 0;
    visibility: hidden;
    @include transition(.3s);
}

.body-overlay-2 {
    background-color: rgba($color: #000000, $alpha: .5);
    width: 100%;
    position: fixed;
    bottom: 0;
    height: calc(100% - 115px);
    z-index: 1;
    left: 0;
    opacity: 0;
    visibility: hidden;
    @include transition(.3s);

    &.overlay-none {
        visibility: hidden;
        opacity: 0;
    }
}

.body-overlay.opened,
.body-overlay-2.opened {
    opacity: 1;
    visibility: visible;
}


/* mean menu customize */
.mean-container a.meanmenu-reveal {
    display: none;
}

.mean-container .mean-nav {
    background: none;
    margin-top: 0;
}

.mean-container .mean-bar {
    padding: 0;
    min-height: auto;
    background: none;
}

.mean-container .mean-nav>ul {
    padding: 0;
    margin: 0;
    width: 100%;
    list-style-type: none;
    display: block !important;
}

.mean-container a.meanmenu-reveal {
    display: none !important;
}

.mean-container .mean-nav ul li a {
    width: 100%;
    padding: 10px 0;
    color: #fff;
    border-top: 1px solid #2a3a57;
    font-size: 13px;
}

.mean-container .mean-nav ul li a.mean-expand {
    margin-top: 0px;
    height: 15px;
    line-height: 14px;
    border-bottom: none;
    padding: 0;
    display: inline-block;
    width: 40px;
    height: 44px;
    line-height: 44px;
}

.mean-container .mean-nav ul li a.mean-expand:hover {
    background: $white;
    color: $black;
}

.mean-container .mean-nav>ul>li:first-child>a {
    border-top: 0;
}



.mean-container .mean-nav ul li a.mean-expand.mean-clicked {
    background: $white;
    color: $black;
}

.mean-container .mean-nav ul li a.mean-expand.mean-clicked i {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    color: $black;
}

.menu_wrapper_one a.active {
    opacity: 0.6;
}

.menu_wrapper_one .nav-link:focus,
.nav-link:hover {
    color: $white;
}

.main-menu.menu_three_wrapper a.active {
    color: $theme-color;
}

.header__bottom.sticky .main-menu ul li a.active {
    color: $theme-color;
}

.main-menu.header_style_nav_five a.active {
    color: $theme-color;
}

.main-menu.header_style_six_menu a.active {
    color: $theme-color;
}

.main-menu.header_style_seven_menu a.active {
    color: $theme-color;
}

.header__style-4 a.active {
    color: $theme-color;
}

@media(max-width:991px) {
    nav#mobile-menu {
        display: none;
    }
}



// sidebar start
.offcanvas {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    z-index: 99999999;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    visibility: hidden;
    background-color: #fff;
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
}

.side__bar a {
    color: $black;
}

.offcanvas-end {
    width: 300px;
}

.offcanvas {
    background: #ffffff none repeat scroll 0 0;
}

.offcanvas-header {
    padding: 30px;
}

.btn-close:focus {
    outline: 0;
    box-shadow: none;
}

.offcanvas-body {
    padding: 0 30px 30px;
}

.offcanvas-header .btn-close {
    background: url(../img/close-icon.svg) center/1em auto no-repeat;
    opacity: 1;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 18px;
    padding: 5px;
    width: 30px;
    height: 30px;
    margin: 0;
    background-color: $theme-color;
    border-radius: 50%;
}

.offcanvas-header .logo {
    margin-top: 10px;
}

.side-info {
    width: auto;
    height: 100%;
    position: static;
    z-index: 9999;
    right: inherit;
    top: 0;
    padding: 0px;
    transition: .6s;
    overflow-y: inherit;
}

.sidebar_sub_menu {
    list-style: none;
}

.sidebar_sub_menu li {
    border-bottom: 1px solid #2a3a57;
    padding-top: 5px;
    padding-bottom: 5px;
}

.sidebar_sub_menu li a {
    opacity: 0.75;
    padding-left: 25px;
    display: inline-block;
    padding-top: 5px;
    padding-bottom: 5px;
}

.sidebar_sub_menu.sub_child_menu li a {
    padding-left: 40px;
    padding-bottom: 5px;
    padding-top: 5px;
    display: inline-block;
}

.iconAdd {
    position: relative;
    color: white;
    border-bottom: 1px solid #2a3a57;
    padding: 10px 0;
}

.sub_child.iconAdd {
    padding: 0px;
}

.side_navBar .about.iconAdd::before {
    display: none;
}

.iconAdd::before {
    content: '+';
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
    font-size: 20px;
    color: #1f1841;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #2a3a57;
    padding: 22px;
}

.sub_child.iconAdd::before {
    padding: 20px;
}

.icon_close {
    position: relative;
    color: white;
    border-bottom: 1px solid #2a3a57;
    padding: 10px 0;
}

.sub_child.icon_close {
    padding: 0px;
}

.icon_close::before {
    content: '-';
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
    font-size: 20px;
    color: #1f1841;
    padding: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #2a3a57;
}

.sub_child.icon_close::before {
    padding: 20px;
}

.side__bar .z-btn-white {
    background: #ffffff;
    border-color: #ffffff;
    color: #1f1841;
}

// bootstrap
// .sidebar_navBar .accordion-item{
//     background: transparent;
//     color: $white;
// }
// .sidebar_navBar button{
//     background: transparent;
//     color: $white;
// }
// .sidebar_navBar .accordion-button:not(.collapsed) {
//     color: $white;
//     background-color: transparent;
//     box-shadow: none;
// }

// .sidebar_navBar .accordion-button::after {
//     flex-shrink: 0;
//     width: 1.25rem;
//     height: 1.25rem;
//     margin-left: auto;
//     content: "+";
//     color: $white;
//     font-size: 20px;
//     font-weight: bold;
//     background-size: 1.25rem;
//     transition: transform 0.2s ease-in-out;
// }
// .sidebar_navBar .accordion-button:not(.collapsed)::after {
//     background-image: none;
//     content: "-";
//     transform: rotate(-180deg);
// }
// .sidebar_navBar .accordion-button:focus {
//     z-index: 3;
//     border: none;
//     outline: 0;
//     box-shadow: none;
// }