/*----------------------------------------*/
/*  09. PRICE CSS START
/*----------------------------------------*/

.price {
    &__inner {
        z-index: 1;
        flex: 1 1 60%;
        margin-right: 50px;

        @media #{$xs} {
            margin-right: 0px;
            margin-bottom: 30px;
        }

        &-2 {
            z-index: 1;
            flex: 1 1 60%;
            margin-left: 50px;

            @media #{$xs} {
                margin-left: 0px;
                margin-bottom: 30px;
            }
        }
    }

    &__item {
        display: flex;
        align-items: center;
        margin-bottom: 50px;

        @media #{$xs} {
            padding: 0px 20px 20px;
            margin-bottom: 40px;
            border-bottom: 1px solid $heading-color;

            &.last {
                padding-bottom: 0px;
                margin-bottom: 0px;
                border-bottom: none;
            }
        }

        & h3 {
            font-weight: 500;
            margin-bottom: 20px;
            color: $heading-color;
        }

        & p {
            margin-bottom: 20px;
        }

        & ul {
            & li {
                font-size: 18px;
                line-height: 26px;
                margin-bottom: 10px;
                padding-left: 22px;

                &:last-child {
                    margin-bottom: 0px;
                }

                & span {
                    position: relative;

                    &::after {
                        position: absolute;
                        content: '';
                        left: -22px;
                        top: 7px;
                        width: 7px;
                        height: 7px;
                        background: $theme-color;
                        @include border-radius(50%);
                    }
                }
            }
        }
    }
}