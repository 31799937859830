/*----------------------------------------*/
/*  13. ACHIEVEMENT CSS START
/*----------------------------------------*/

.achievement {
    &__area {
        &.sticky {
            position: sticky;
            top: 77px;
            z-index: 99;
            background: $black;

            @media #{$xs} {
                top: 70px;
            }
        }

        & .scrollLinks {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 15px 0px;

            @media #{$xs} {
                flex-direction: column;
            }

            & button {
                background: transparent;
                color: $white;
                font-weight: 600;
                font-size: 18px;
                margin: 0px 30px;

                @media #{$xs} {
                    margin: 0px;
                    margin-bottom: 8px;
                    padding-bottom: 8px;
                    width: 100%;
                    border-bottom: 1px solid $black-soft-3;

                    &:last-child {
                        margin: 0px;
                        padding: 0px;
                        border: none;
                    }
                }
            }
        }

        &.fixBox {
            scroll-margin-top: 100px;

            @media #{$xs} {
                scroll-margin-top: 180px;
            }
        }
    }

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;

        @media #{$xs} {
            flex-direction: column;
        }
    }

    &__item {
        flex: 1 1 24%;
        padding: 30px 22px;
        background: $white;
        border: 1px solid $grey-4;
        @include border-radius(10px);

        & i {
            font-size: 30px;
            display: block;
            margin-bottom: 20px;
            backface-visibility: hidden;
            -webkit-transform: translate3d(0, 0, 0);
            -moz-transform: translate3d(0, 0, 0);
            -ms-transform: translate3d(0, 0, 0);
            -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
            -webkit-transition: transform .3s cubic-bezier(.21, .6, .44, 2.18);
            -moz-transition: transform .3s cubic-bezier(.21, .6, .44, 2.18);
            -ms-transition: transform .3s cubic-bezier(.21, .6, .44, 2.18);
            -o-transition: transform .3s cubic-bezier(.21, .6, .44, 2.18);
            transition: transform .3s cubic-bezier(.21, .6, .44, 2.18);
        }

        & h3 {
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 15px;
        }

        & ul {
            & li {
                margin-bottom: 10px;
                padding-left: 24px;

                &:last-child {
                    margin-bottom: 0px;
                }

                & span {
                    position: relative;

                    &::after {
                        position: absolute;
                        content: '';
                        left: -22px;
                        top: 7px;
                        width: 6px;
                        height: 6px;
                        background: $heading-color;
                        @include border-radius(50%);
                    }
                }
            }
        }

        &:hover {
            @include box-shadow(0px 0px 50px 0px rgba(0, 0, 0, 0.08));
            border-color: $white;

            & i {
                -webkit-transform: translate3d(0, -10px, 0);
                -moz-transform: translate3d(0, -10px, 0);
                -ms-transform: translate3d(0, -10px, 0);
                -o-transform: translate3d(0, -10px, 0);
                transform: translate3d(0, -10px, 0);
            }
        }
    }
}